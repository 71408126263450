/**
 * Rippe effect
 */
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 400ms linear;
  background-color: var(--colors-purple-20);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/**
 * Background loader animation
 */

.component-loader {
  background-color: #11111111;
  animation: grey-shift 1.5s ease-in-out infinite;
}

@keyframes grey-shift {
  0% {
    background-color: #11111111;
  }
  50% {
    background-color: #00000033;
  }
  100% {
    background-color: #11111111;
  }
}
