@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sigmar+One&display=swap");
@import url("./animations.css");

body {
  background: "#F6F8FA";
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --fonts-text: "Poppins";

  --colors-background: #f6f8fa;
  --colors-purple-dark: #190933;
  --colors-purple: #644cf8;
  --colors-purple-90: #644cf890;
  --colors-purple-80: #644cf880;
  --colors-purple-60: #644cf860;
  --colors-purple-40: #644cf840;
  --colors-purple-20: #644cf820;
  --colors-blue: #08c3f8;
  --colors-blue-20: #08c3f820;
  --colors-green: #63ce99;
  --colors-green-20: #63ce9920;
  --colors-orange: #ff674d;
  --colors-grey: #f6f8fa;
  --colors-grey-dark: #474d6c;
  --colors-grey: #474d6c;
  --colors-grey-90: #474d6c90;
  --colors-grey-80: #474d6c80;
  --colors-grey-70: #474d6c70;
  --colors-grey-60: #474d6c60;
  --colors-grey-50: #474d6c50;
  --colors-grey-40: #474d6c40;
  --colors-grey-30: #474d6c30;
  --colors-grey-20: #474d6c20;
  --colors-grey-10: #474d6c10;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .recharts-legend-item { font-size: 12px; } */

.recharts-cartesian-axis-tick line {
  stroke: none !important;
}

#ageChart .recharts-cartesian-axis-tick:first-child text {
  font-size: 0 !important;
}

#ageChart .recharts-cartesian-axis-tick:last-child text {
  font-size: 0 !important;
}

#genderChart .recharts-legend-item {
  margin-bottom: 10px;
}

.MuiSlider-thumb {
  background: "#fff !important";
}
